<template>
  <v-row align="center" justify="center" class="pa-4">
    <v-col>
      <div class="fn24-weight7">Knowledge Hub</div>
    </v-col>
    <v-card class="pa-8" width="100%">
      <v-form ref="createForm" v-model="checkValidate" :lazy-validation="lazy">
        <v-row>
          <v-row class="ma-5" align="center" justify="end">
            <v-btn @click="cancel()" class="mr-4">กลับ</v-btn>
            <v-btn @click="submit()" color="primary">บันทึก</v-btn>
          </v-row>
        </v-row>
      </v-form>
    </v-card>
  </v-row>
</template>
<script>
import { Decode } from "@/services";
import draggable from "vuedraggable";
export default {
  components: {
    draggable,
  },
  data() {
    return {
      id: "",
      category: "",
      itemsCategory: [],
      type: "",
      itemsType: [],
      brand: "",
      itemsBrand: [],
      company: [],
      itemsCompany: [],
      sku: "",
      productName: "",
      shortDesc: "",
      termDesc: "",
      description: "",
      editorOption: {
        modules: {
          toolbar: [
            ["bold", "italic", "underline", "strike"],
            ["blockquote", "code-block"],
            [{ header: 1 }, { header: 2 }],
            [{ list: "ordered" }, { list: "bullet" }],
            [{ script: "sub" }, { script: "super" }],
            [{ indent: "-1" }, { indent: "+1" }],
            [{ direction: "rtl" }],
            [{ size: ["small", false, "large", "huge"] }],
            [{ header: [1, 2, 3, 4, 5, 6, false] }],
            [{ font: [] }],
            [{ color: [] }, { background: [] }],
            [{ align: [] }],
            ["clean"],
            [
              "link",
              // "image",
              // "video"
            ],
          ],
          syntax: {
            highlight: (text) => hljs.highlightAuto(text).value,
          },
        },
      },
      unit: "",
      price: "",
      img: [],
      DataImage: [],
      product_image: [],
      productImgUrl: [],
      pic1: "",
      picture_1: "",
      showImage: "",
      imageName1: "",
      activeFlag: true,
      stockAmt: "",
      cost: "",
      deliveryFee: "",
      setupFee: "",
      isHilight: false,
      showIndex: null,
      companyName: [],
      // listDescription: [],
      productPriceList: [],
      productOptionList: [],

      // approveFlag: "",
      // itemsapproveFlag: [
      //   { text: "ยังไม่อนุมัติ", value: "0" },
      //   { text: "อนุมัติ", value: "1" },
      //   { text: "ตีกลับ", value: "2" },
      // ],
      rules: {
        required: [(v) => !!v || "กรุณากรอกข้อมูล"],
      },
      checkValidate: true,
      lazy: false,
    };
  },
  async created() {
    var productdata = JSON.parse(
      Decode.decode(localStorage.getItem("productdata"))
    );
    console.log("productdata", productdata);
    this.id = productdata.id;
    this.getOne();
    // this.itemsCategory = cateDropdown.data.data;
    // this.itemsType = typeDropdown.data.data;
    // console.log(this.itemsCategory, this.itemsType);
  },
  computed: {
    editor() {
      return this.$refs.myTextEditor.quill;
    },
    contentCode() {
      return hljs.highlightAuto(this.description).value;
      // return hljs.highlightAuto(this.termDesc).value;
    },
  },
  onFocusOut: function (e) {
    this.description = e.target.innerHTML;
  },
  methods: {
    onEditorChange({ quill, html, text }) {
      console.log("editor change!", quill, html, text);
      this.description = html;
    },
    onEditorChangetermDesc({ quill, html, text }) {
      console.log("editor change!", quill, html, text);
      this.termDesc = html;
    },
    async addproductOptionList() {
      this.productOptionList.push({
        optionName: this.optionName,
      });
    },
    deleteproductOptionList() {
      if (this.productOptionList.length > 0) {
        this.productOptionList.pop();
      }
    },
    async addRow1() {
      this.productPriceList.push({
        price: null,
        companyId: this.companyId,
      });
      console.log("company", this.productPriceList);
    },
    deleteRow1() {
      if (this.productPriceList.length > 0) {
        this.productPriceList.pop();
      }
    },
    showPicture1(e) {
      // console.log('index',index);
      const files = e.target.files;
      if (files[0] !== undefined) {
        this.imageName1 = files[0].name;
        const element = files[0];
        const reader = new FileReader();
        reader.readAsDataURL(element);
        reader.onload = () => {
          this.pic1 = reader.result;
          this.showImage = URL.createObjectURL(element);
          // console.log(this.pic1);
        };
      }
    },
    changePic1() {
      document.getElementById("picTure1").click();
    },
    onPickFile() {
      document.getElementById("file_input").click();
    },
    UploadImage() {
      for (let i = 0; i < this.DataImage.length; i++) {
        const element = this.DataImage[i];
        const reader = new FileReader();
        reader.readAsDataURL(element);
        reader.onload = () => {
          var resultReader = reader.result;
          this.productImgUrl = URL.createObjectURL(element);
          this.product_image.push({
            image_data: resultReader,
            productImgUrl: this.productImgUrl,
            name: this.DataImage[i].name,
            id: "-1",
          });
        };
      }
    },
    onMove({ relatedContext, draggedContext }) {
      const relatedElement = relatedContext;
      const draggedElement = draggedContext;
      return (
        (!relatedElement || !relatedElement.fixed) && !draggedElement.fixed
      );
    },
    RemoveImage(index, item) {
      console.log(index, item);
      // if (item.id !== "-1") {
      //   this.Remove_img.push({
      //     id: item.id,
      //   });
      // }
      this.product_image.splice(index, 1);
    },
    async getOne() {
      const cateDropdown = await this.axios.get(
        `${process.env.VUE_APP_API}/categories`
      );
      const typeDropdown = await this.axios.get(
        `${process.env.VUE_APP_API}/types`
      );
      const brandDropdown = await this.axios.get(
        `${process.env.VUE_APP_API}/brands`
      );
      const companyDropdown = await this.axios.get(
        `${process.env.VUE_APP_API}/companies`
      );
      this.itemsCategory = cateDropdown.data.data;
      this.itemsType = typeDropdown.data.data;
      this.itemsBrand = brandDropdown.data.data;
      this.itemsCompany = companyDropdown.data.data;
      console.log("itemsBrand", this.itemsBrand);
      //////////get dropdown list/////////////
      const response = await this.axios.get(
        `${process.env.VUE_APP_API}/products/` + this.id
      );
      console.log("Product", response);
      const Product = response.data.data;
      this.id = Product.id;
      this.activeFlag = Product.activeFlag;
      this.category = Product.categoryId;
      this.type = Product.typeId;
      this.brand = Product.brandId;
      // this.company = Product.company;
      this.sku = Product.sku;
      this.productName = Product.productName;
      this.description = Product.description;
      this.shortDesc = Product.shortDesc;
      this.termDesc = Product.termDesc;
      this.unit = Product.unit;
      this.price = Product.price;
      // this.img = Product.img;
      this.cost = Product.cost;
      this.stockAmt = Product.stockAmt;
      this.deliveryFee = Product.deliveryFee.toString();
      this.setupFee = Product.setupFee;
      this.isHilight = Product.isHilight;
      this.showIndex = Product.showIndex;
      // this.companyName = Product.productPriceList.company;
      this.productPriceList = Product.productPriceList;
      this.productOptionList = Product.productOptionList;
      this.showImage = Product.imgUrl;
      this.product_image = Product.productImgs;
      this.productImgUrl = Product.productImgs.productImgUrl;

      // console.log("companyNamecompanyName", this.companyName);
    },

    cancel() {
      this.$router.push("ManageKnowledge");
    },
    async submit() {
      if (this.$refs.createForm.validate(true)) {
        const productData = {
          categoryId: this.category,
          typeId: this.type,
          brandId: this.brand,
          sku: this.sku,
          productName: this.productName,
          description: this.description,
          shortDesc: this.shortDesc,
          termDesc: this.termDesc,
          unit: this.unit,
          price: this.price,
          activeFlag: this.activeFlag,
          cost: this.cost,
          stockAmt: this.stockAmt,
          deliveryFee: this.deliveryFee,
          setupFee: this.setupFee,
          isHilight: this.isHilight,
          showIndex: this.showIndex,
          imgUrl: this.pic1,
          imgUrlDesc: this.product_image,
          productPriceList: this.productPriceList,
          productOptionList: this.productOptionList,
        };
        var user = JSON.parse(
          Decode.decode(localStorage.getItem("userBiAdmin"))
        );
        const auth = {
          headers: {
            Authorization: `Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6MjcsImVtYWlsIjoiaXR0aXd1dC53b25nM0BnbWFpbC5jb20iLCJwYXNzd29yZCI6IiQyYiQxMCRqU0UvY3RsSGhmTmJYemV5QXAvUHhPc3NOSS9HNG5NTHNsNlNBanFySzlVYzFMQmhDazh1UyIsImZpcnN0TmFtZSI6InNkZiIsImxhc3ROYW1lIjoic2RmIiwidGVsQ29kZSI6Iis2NiIsInRlbE51bWJlciI6IjIzNDU0NSIsImxpbmUiOiJkZnMiLCJnZW5kZXIiOiIiLCJhZ2UiOjEyLCJsaWNlbnNlTm8iOiIiLCJzY2hvb2wiOiIiLCJleHBlcmllbmNlIjoiZnNkZiIsImFsbGVyZ3kiOiJzZGYiLCJtaW5GZWUiOjEwLCJtYXhGZWUiOjIwLCJwcm92aW5jZSI6IuC4quC4oeC4uOC4l-C4o-C4m-C4o-C4suC4geC4suC4oyIsInVzZXJSZXF1ZXN0Ijoic2RmIiwiaWRDYXJkSW1nIjpudWxsLCJlZHVjYXRpb25JbWciOm51bGwsIndvcmtpbmdBcmVhIjoiamtqIiwibGF0IjpudWxsLCJsbmciOm51bGwsInNjaGVkdWxlIjoiWzIsM10iLCJyb2xlIjoiVVNFUiIsImlzUHJvIjpmYWxzZSwiaXNQdWJsaWMiOmZhbHNlLCJhY3RpdmVGbGFnIjp0cnVlLCJjcmVhdGVkQXQiOiIyMDIxLTExLTExVDA1OjQ3OjM4LjAwMFoiLCJ1cGRhdGVkQXQiOiIyMDIxLTExLTExVDExOjUxOjM2LjAwMFoiLCJqb2JJZCI6NCwiZWR1Y2F0aW9uSWQiOjEsImxpY2Vuc2VJZCI6MSwic2VydmljZVR5cGVJZCI6MSwiZmVlQ3VycmVuY3lJZCI6MSwiZmVlRnJlcXVlbmN5SWQiOjIsImlhdCI6MTYzNjkwODA0Mn0.4ctskWtU_WO5pDZMDa6M8vxLFCJZPaRCNxhNMiwl0-E`,
          },
        };
        console.log("data", productData);
        const response = await this.axios.put(
          `${process.env.VUE_APP_API}/products/` + this.id,
          productData,
          auth
        );
        console.log("response product", response);
        if (response.data.response_status == "SUCCESS") {
          this.$swal.fire({
            icon: "success",
            text: `แก้ไขข้อมูลสินค้าสำเร็จ`,
            showConfirmButton: false,
            timer: 1500,
          });
          this.$router.push("ManageKnowledge");
        } else {
          this.$swal.fire({
            icon: "error",
            text: `แก้ไขข้อมูลสินค้าไม่สำเร็จ`,
            showConfirmButton: false,
            timer: 1500,
          });
        }
      }
    },
  },
};
</script>
